.root {
  display: flex;
  padding: 0 1rem;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  opacity: 0.75;
  flex-grow: 1;
  padding: 0 2.5rem;

  & a {
    text-decoration: none;
    color: var(--active);

    &:hover {
      text-decoration: underline;
      color: var(--text);
    }
  }
}

.nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navItem {
  padding-left: 1rem;
  color: var(--text-light);
}

.button {
  composes: button from "Layout.module.scss";
}
