@import "../util/helpers";

:root {
  --active: hsl(204, 38%, 33%);
  --done: hsl(118, 45%, 50%);
  --text: #1b1b1b;
  --text-light: hsl(219, 15%, 55%);
  --background: hsl(208, 40%, 95%);
  --header: 'Libre Baskerville', serif;
  --body: 'Source Sans Pro', sans-serif;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--text);
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.button {
  background: transparent;
  padding: 0.5rem 1rem;
  color: var(--active);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  outline: none;
  border: 0;
  border-radius: 10px;
  transition: all .15s ease-in;
  box-shadow: 10px 10px 22px #dce3ea, -10px -10px 22px #f8ffff;

  &:hover {
    box-shadow:  10px 10px 22px #ced5db, -10px -10px 22px #ffffff;
  }

  &:active, &:focus {
    @include activeButton;
  }
}

@keyframes shadowActive {
  0% { box-shadow: 10px 10px 22px #ced5db, -10px -10px 22px #ffffff; }
  25% { box-shadow: none; }
  100% { box-shadow: inset 10px 10px 22px #ced5db, inset -10px -10px 22px #ffffff; }
}


.input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid rgba(83, 83, 83, 0.2);
  min-width: 12rem;
  max-width: 90vw;
  min-height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}