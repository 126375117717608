.wrapper {
  padding: 0 4vw;
}

.actions {
  display: flex;
  justify-content: space-evenly;
  margin: 10vh  auto 0;
  padding: 0 1rem;
  max-width: 980px;
}

.section {
  margin-bottom: 1.5rem;

  > label {
    display: block;
    font-weight: 16px;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
}

.button {
  composes: button from "../components/Layout.module.scss";
  font-size: 12px;
}

.input {
  composes: input from "../components/Layout.module.scss";
}

.fieldset {
  display: flex;

  & > * {
    margin-right: 1rem;
  }
}

.result {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 0;
  min-height: 3em;
  line-height: 1.5em;
}

.footer {
  padding: 4rem;
  font-style: italic;
  font-size: 18px;
}

.radio {
  font-size: 18px;

  & + & {
    margin-left: 1rem;
  }
}